/* eslint-disable */
const enabledLocales = ["it"]

const locales = {}

enabledLocales.forEach(locale => {
  const base = require(`./${locale.slice(-2)}.json`)

  let specific = {}

  try {
    specific = require(`./${locale}.json`) || {}
  } catch (error) {}

  locales[locale] = { ...base, ...specific }
})

export default locales

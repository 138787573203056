import React from "react"
import _ from "lodash"
import { FormattedNumber } from "react-intl"

export default {
  getLocale: () => {
    return "it"
  },
  isMobile: () =>
    typeof window !== "undefined" &&
    window.matchMedia("(max-width: 767px)").matches,
  isDesktop: () =>
    typeof window !== "undefined" &&
    window.matchMedia("(min-width: 1024px)").matches,
  isDevMode: () => {
    return window && window.location.href.indexOf("__DEVMODE__") > -1
  },
  getRandomInt: (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)

    return Math.floor(Math.random() * (max - min)) + min
  },
  copyToClipboard: () => {
    let textArea, copy

    function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i)
    }

    function createTextArea(text) {
      textArea = document.createElement("textArea")
      textArea.value = text
      document.body.appendChild(textArea)
    }

    function selectText() {
      let range, selection

      if (isOS()) {
        range = document.createRange()
        range.selectNodeContents(textArea)
        selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
        textArea.setSelectionRange(0, 999999)
      } else {
        textArea.select()
      }
    }

    function copyToClipboard() {
      document.execCommand("copy")
      document.body.removeChild(textArea)
    }

    copy = function(text) {
      createTextArea(text)
      selectText()
      copyToClipboard()
    }

    return {
      copy: copy,
    }
  },
  _localStorage: {
    set(key, value) {
      try {
        return localStorage.setItem(key, value)
      } catch (exception) {
        return null
      }
    },
    get(key) {
      try {
        return localStorage.getItem(key)
      } catch (e) {
        return null
      }
    },
    getJson(key) {
      try {
        return JSON.parse(localStorage.getItem(key))
      } catch (e) {
        return null
      }
    },
    remove(key) {
      try {
        return localStorage.removeItem(key)
      } catch (e) {
        return null
      }
    },
  },
  validate: (page, state) => {
    const validators = page(state)
    let error = false

    _.forEach(validators, validator => {
      error = error || !validator()
    })

    return !error
  },
  getPrize: ({ state, prize, intl }) => {
    if (state.data.quizMode !== "CUSTOM_PRIZE" && state.data.hasRealCurrency) {
      return (
        <FormattedNumber
          value={
            typeof prize !== "undefined" ? prize : state.game.prizePerWinner
          }
          format={state.data.currencyName}
        />
      )
    }

    if (state.data.quizMode !== "CUSTOM_PRIZE" && !state.data.hasRealCurrency) {
      return `${
        typeof prize !== "undefined"
          ? prize
          : state.game.prizePerWinner.toFixed(0)
      } ${intl.formatMessage({
        id: `currencies.plural.${state.data.currencyName}`,
      })}`
    }

    return state.data.customPrizeName
  },
  getGameStatus: state => {
    if (state.game.status === "end") {
      if (state.game.isUserWinner) {
        return "user-won"
      }

      return "user-lost"
    }

    if (state.game.spectatorMode) {
      return "user-spectator"
    }

    return "user-playing"
  },
}

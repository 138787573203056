export default {
  name: "QuizTime",
  oxide: process.env.GATSBY_OXIDE_URL || "https://quiztime.rewave.io",
  sodium:
    process.env.GATSBY_SODIUM_URL ||
    (process.env.NODE_ENV && process.env.NODE_ENV === "production"
      ? "https://qt-sodium.herokuapp.com"
      : "http://127.0.0.1:3456"),
  domainRaw:
    process.env.NODE_ENV && process.env.NODE_ENV === "production"
      ? process.env.GATSBY_DOMAIN
      : "localhost:8080",
  domain:
    process.env.NODE_ENV && process.env.NODE_ENV === "production"
      ? `https://${process.env.GATSBY_DOMAIN}`
      : "https://localhost:8080",
  quizRules: {
    secondsToStart: 5,
    secondsToAnswer: 10,
    intervalBetweenQuestions: 3,
    points: isSpectator => ({
      standard: isSpectator ? 1 : 3,
      gold: isSpectator ? 2 : 6,
    }),
  },
  shortLink: `https://${process.env.GATSBY_DOMAIN}`,
  loginRoute: "/",
  fb: {
    appId: process.env.GATSBY_FACEBOOK_APP_ID,
  },
  gtmId: "GTM-K72JS4N",
  onesignal: {
    appId: "875ad2a0-c9d3-44f9-bfa2-c6ac09c96f2e",
  },
  intl: {
    formats: {
      number: {
        EUR: {
          style: "currency",
          currency: "EUR",
        },
      },
    },
  },
}

import React from "react"
import { AuthProvider } from "./src/contexts/auth"
// import Layout from "./src/components/_layout";
import { StateInspector } from "reinspect"
import { IntlProvider, addLocaleData } from "react-intl"
import config from "./src/config"
import locales from "./src/locales"
import utils from "./src/utils"

addLocaleData({ locale: utils.getLocale(), pluralRuleFunction: () => {} })

const TranslationWrapper = ({ dangerouslySetInnerHTML, ...props }) =>
  dangerouslySetInnerHTML ? (
    <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...props} />
  ) : (
    <React.Fragment {...props} />
  )

export const wrapPageElement = ({ props, element }) => {
  return (
    <StateInspector>
      <AuthProvider>
        <IntlProvider
          locale={utils.getLocale()}
          defaultLocale={utils.getLocale()}
          messages={locales[utils.getLocale()]}
          textComponent={TranslationWrapper}
          formats={config.intl.formats}
          defaultFormats={config.intl.formats}
        >
          {/* <Layout locale={utils.getLocale()} {...props}> */}
          {element}
          {/* </Layout> */}
        </IntlProvider>
      </AuthProvider>
    </StateInspector>
  )
}

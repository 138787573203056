import React from "react"
import axios from "axios"
import moment from "moment"
import { useState } from "reinspect"
import config from "../../config"
import utils from "../../utils"

const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
  const [isAuth, setAuthState] = useState(0, "is-auth")
  const [isLoading, setLoadingState] = useState(true)
  const [error, setError] = useState(0, "auth-error")

  // let secondLevelDomain
  // let matchSecondLevelDomain = config.domainRaw.match(
  //   /[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?$/
  // )

  // if (matchSecondLevelDomain) {
  //   secondLevelDomain = matchSecondLevelDomain[0]
  // } else {
  //   secondLevelDomain = "localhost"
  // }

  React.useEffect(() => {
    init()
  }, [])

  const init = async () => {
    if (isTokenExpired()) {
      try {
        await refreshToken()
      } catch (e) {}
    } else {
      setAuth(isLogged())
    }

    window.fbAsyncInit = () => {
      window.FB.init({
        appId: config.fb.appId,
        status: true,
        xfbml: false,
        version: "v3.2",
      })
    }
    ;(function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/en_US/sdk.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
  }

  const setAuth = async isAuthenticated => {
    if (!isAuthenticated) {
      setAuthState(false)
      setLoadingState(false)
      return
    }

    try {
      await fetchUserInfo()

      setAuthState(true)
      setLoadingState(false)
    } catch (e) {
      isAuthenticated = false
    }

    if (!isAuthenticated) {
      try {
        refreshToken()
      } catch (e) {}
    }
  }

  const refreshToken = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const jwt = localStorage.getItem("jwt")

        const response = await axios(`${config.oxide}/api/auth/refresh`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        })

        localStorage.setItem("jwt", response.data.access_token)
        localStorage.setItem(
          "jwt_expiration_date",
          moment()
            .add(response.data.expires_in, "seconds")
            .format()
        )

        setAuthState(true)
        setLoadingState(false)
        resolve()
      } catch (e) {
        setError("SERVER_ERROR")
        setLoadingState(false)
        reject()
      }

      try {
        fetchUserInfo()
      } catch (e) {}
    })
  }

  const isTokenExpired = () => {
    const jwt = localStorage.getItem("jwt")
    const jwt_expiration_date = localStorage.getItem("jwt_expiration_date")

    return jwt && jwt_expiration_date && moment().isAfter(jwt_expiration_date)
  }

  const isLogged = () => {
    return !!localStorage.getItem("jwt")
  }

  const logout = () => {
    localStorage.removeItem("jwt")
    setAuth(false)
  }

  const facebookLogin = () => {
    return new Promise((resolve, reject) => {
      window.FB.login(
        response => {
          if (!response || response.status !== "connected") {
            console.log(response)
            reject()

            return
          }

          resolve(response.authResponse.accessToken)
        },
        {
          scope: "email",
          return_scopes: true,
        }
      )
    })
  }

  const login = async ({ provider = "facebook", credentials = {} }) => {
    return new Promise(async (resolve, reject) => {
      let fbToken
      if (provider === "facebook") {
        try {
          fbToken = await facebookLogin()
        } catch (e) {
          return reject(e)
        }
      }

      try {
        const response = await axios(`${config.oxide}/api/auth/login`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data: Object.assign(
            {
              customer_id: process.env.GATSBY_CLIENT_ID,
            },
            provider === "facebook"
              ? {
                  fb_token: fbToken,
                }
              : credentials
          ),
        })

        if (typeof window !== "undefined") {
          window.TrackJS &&
            window.TrackJS.addMetadata("email", credentials.email)
        }

        localStorage.setItem("jwt", response.data.access_token)
        localStorage.setItem(
          "jwt_expiration_date",
          moment()
            .add(response.data.expires_in, "seconds")
            .format()
        )

        setAuth(true)

        return resolve(response)
      } catch (e) {
        reject(e)
      }
    })
  }

  const register = ({ data }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios(`${config.oxide}/api/auth/register`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data: Object.assign(
            {
              customer_id: process.env.GATSBY_CLIENT_ID,
            },
            data
          ),
        })

        localStorage.setItem("jwt", response.data.access_token)

        setAuth(true)

        resolve(response)
      } catch (e) {
        reject(e)
      }
    })
  }

  const fetchUserInfo = async () => {
    const jwt = localStorage.getItem("jwt")

    const me = await axios(`${config.sodium}/api/auth/me`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-JWT-TOKEN": jwt,
      },
    })

    localStorage.setItem("me", JSON.stringify(me.data))
  }

  const getUserInfo = () => {
    if (!isLogged()) {
      return null
    }

    try {
      const me = JSON.parse(localStorage.getItem("me"))

      if (typeof window !== "undefined") {
        window.TrackJS && window.TrackJS.addMetadata("email", me.email)
      }

      return me
    } catch (e) {
      return null
    }
  }

  const getAccessToken = () => {
    const jwt = utils._localStorage.get("jwt")

    return jwt
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuth,
        getUserInfo,
        getAccessToken,
        login,
        logout,
        facebookLogin,
        register,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }

// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-index-js": () => import("/opt/render/project/src/src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-register-index-js": () => import("/opt/render/project/src/src/templates/register/index.js" /* webpackChunkName: "component---src-templates-register-index-js" */),
  "component---src-templates-faq-index-js": () => import("/opt/render/project/src/src/templates/faq/index.js" /* webpackChunkName: "component---src-templates-faq-index-js" */),
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-i-index-js": () => import("/opt/render/project/src/src/pages/i/index.js" /* webpackChunkName: "component---src-pages-i-index-js" */)
}

